import { PortalRequest as request } from "@/utils/request";

// 新用户注册
export const setUserRegisterEmail = (data) => {
  return request({
    url: "/res-user/register/email",
    method: "post",
    data,
  });
};
export const setUserRegisterMobile = (data) => {
  return request({
    url: "/res-user/register/mobile",
    method: "post",
    data,
  });
};

// 重置密码
export const setResetPassword = (data) => {
  return request({
    url: "/res-user/reset-password",
    method: "post",
    data,
  });
};
